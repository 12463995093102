import React, { useState, useEffect, lazy, Suspense } from 'react';
import styles from '../css/NewsletterSignup.module.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFacebook, FaTwitter, FaInstagram, FaTelegram, FaYoutube } from 'react-icons/fa';
import { AiOutlineTikTok } from 'react-icons/ai';
import Link from 'next/link';

// Dynamically import GoogleOAuthProvider and GoogleLogin
const GoogleOAuthProviderLazy = lazy(() =>
  import('@react-oauth/google').then(module => ({ default: module.GoogleOAuthProvider }))
);

const GoogleLoginLazy = lazy(() =>
  import('@react-oauth/google').then(module => ({ default: module.GoogleLogin }))
);

const ToastContainerLazy = lazy(() =>
  import('react-toastify').then(module => ({ default: module.ToastContainer }))
);

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setLoggedInUser(JSON.parse(userData));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://dailycoinmarket.com/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, category: 'simple' }),
      });

      if (response.ok) {
        setIsSubscribed(true);
        setEmail('');
        toast.success('Subscription successful');
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.error || 'Subscription failed';
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error during subscription');
    } finally {
      setShowToast(true);
    }
  };

  const handleLoginSuccess = async (response) => {
    try {
      const res = await fetch('https://dailycoinmarket.com/api/google-login', {
        method: 'POST',
        body: JSON.stringify({ token: response.credential }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (!res.ok) {
        throw new Error('Google login failed');
      }

      const userData = await res.json();
      const { email, username } = userData;

      localStorage.setItem('userData', JSON.stringify({ email, username }));
      setLoggedInUser({ email, username });

      const subscribeRes = await fetch('https://dailycoinmarket.com/api/subscribe2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, category: 'simple' }),
      });

      if (!subscribeRes.ok) {
        throw new Error('Failed to subscribe user');
      }

      const subscribeData = await subscribeRes.json();
    } catch (error) {
      console.error('Error during Google login:', error);
      toast.error('Google login failed');
    }
  };

  const handleGoogleFailure = (response) => {
    console.error('Google Sign In failed', response);
    toast.error(`Google Sign In failed: ${response.error}`);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    setLoggedInUser(null);
  };

  return (
    <Suspense fallback={<div>Loading components...</div>}>
      <GoogleOAuthProviderLazy clientId="10987517040-vhurkduo4aqq58j52d6fvei5rqj5vvq8.apps.googleusercontent.com">
        <div className={styles.newsletterSignup}>
          <div className={styles.textContainer}>
            <p className={styles.largeText}>Latest crypto news in your inbox</p>
            <p className={styles.smallText}>Get the newsletter directly to your inbox</p>
            {loggedInUser && (
              <p className={styles.loggedInMessage}>Logged in as {loggedInUser.username}</p>
            )}
            <div className={styles.icons}>
              <Link href="https://www.facebook.com/people/Daily-Coin-Market/61562279513612" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
              </Link>
              <Link href="https://x.com/DailyCoinMarket" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </Link>
              <Link href="https://www.instagram.com/dailycoinsmarket" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </Link>
              <Link href="https://www.tiktok.com/@dailycoinmarket" target="_blank" rel="noopener noreferrer">
                <AiOutlineTikTok />
              </Link>
              <Link href="https://t.me/dailycoinmarket" target="_blank" rel="noopener noreferrer">
                <FaTelegram />
              </Link>
              <Link href="https://www.youtube.com/@DailyCoinMarket" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </Link>
            </div>
          </div>
          <div className={styles.formContainer}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email..."
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className={styles.buttonContainer}>
                <button type="submit" disabled={isSubscribed}>
                  {isSubscribed ? 'Subscribed' : 'Subscribe'}
                </button>
                {!loggedInUser && (
                  <div className={styles.googleLogin}>
                    <Suspense fallback={<div>Loading Google Login...</div>}>
                      <GoogleLoginLazy
                        onSuccess={handleLoginSuccess}
                        onError={handleGoogleFailure}
                        text="Subscribe with Google"
                      />
                    </Suspense>
                  </div>
                )}
              </div>
            </form>
          </div>
          {showToast && (
            <Suspense fallback={<div>Loading Toast...</div>}>
              <ToastContainerLazy />
            </Suspense>
          )}
        </div>
      </GoogleOAuthProviderLazy>
    </Suspense>
  );
};

export default NewsletterSignup;
