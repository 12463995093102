import React, { useState } from 'react';
import Link from 'next/link'; // Import Link from next/link
import styles from '../css/HeadingBar.module.css'; // Adjust the path as needed

const HeadingBar = ({ headlines = [] }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles.headingBar}>
      <div 
        className={`${styles.headlineContainer} ${isHovered ? styles.hovered : ''}`} 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave}
      >
        {headlines.length > 0 ? (
          <div className={`${styles.headlineWrapper} ${isHovered ? styles.paused : ''}`}>
            {headlines.concat(headlines).map((headline, index) => (
              <div key={index} className={styles.headline}>
                <Link href={`/news/${headline.slug}`}>
                  <span>{headline.title}</span>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.headline}>
            <span>No headlines available</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeadingBar;
