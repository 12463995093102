import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from '../css/EP.module.css';

const EarnProgramBox = () => {
  return (
    <div className={styles.earnProgramContainertop}>
    <Link href="/earn_program_2024" className={styles.earnProgramContainer} >
      
        <div className={styles.content}>
          <h2 className={styles.title}>EARN YOUR STARTING INVESTMENT FROM US FREE!</h2>
          <p className={styles.description}>
            Join our Earn Program and stand a chance to win up to 
            <span className={styles.highlight}> $1000!</span> Click here to start your journey. 
            Follow our signals and pave your way to millions.
          </p>
          <span className={styles.ctaButton}>GET NOW</span>
        </div>
        <div className={styles.imageContainer}>
          <Image 
            src="https://dailycoinmarket.com/upload/earnbanner.webp"
            alt="Earn Program Banner"
            width={100}
            height={100}
            className={styles.image}
          />
        </div>
    
    </Link>
    </div>
  );
};

export default EarnProgramBox;

