import React, { useState, useEffect } from 'react';
import styles from '../css/CryptoBar.module.css'; // Import CSS module for styling
import Image from 'next/image'; // Import Image from next/image

const CryptoBar = () => {
  const [cryptos, setCryptos] = useState([]);
  const [displayCount, setDisplayCount] = useState(5); // Number of cryptocurrencies to display
  const [loading, setLoading] = useState(true); // Add loading state

  const handleResize = () => {
    // Adjust number of cryptos to display based on screen width
    if (window.innerWidth <= 768) {
      setDisplayCount(3); // Display 3 cryptos for smaller screens
    } else if (window.innerWidth <= 1040) {
      setDisplayCount(5); 
    } else {
      setDisplayCount(7); // Display 7 cryptos for screens wider than 1040px
    }
  };

  useEffect(() => {
    // Fetch data from CoinGecko API
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,ripple,litecoin,cardano,chainlink,stellar,solana');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Extract necessary data (id, name, current_price, price_change_percentage_24h, image)
        const formattedData = data.map(crypto => ({
          id: crypto.id,
          name: crypto.name,
          price: crypto.current_price,
          change: crypto.price_change_percentage_24h,
          icon: crypto.image
        }));
  
        // Check if browser supports WebP format
        const supportsWebP = (() => {
          const elem = document.createElement('canvas');
          if (!elem.getContext || !elem.getContext('2d')) {
            return false;
          }
          // Base64 representation of a 1x1 transparent WebP image
          return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        })();
  
        // Modify image URLs to serve WebP format if supported
        const webpFormattedData = formattedData.map(crypto => ({
          ...crypto,
          icon: supportsWebP ? `${crypto.icon}?w=200&h=200&fit=crop&auto=format` : `${crypto.icon}?w=200&h=200&fit=crop` // Append WebP query if supported
        }));
  
        setCryptos(webpFormattedData);
      } catch (error) {
        console.error('Error fetching cryptocurrency data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Initial call to set display count on component mount
    handleResize();
    // Cleanup function for removing event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.cryptoBar}>
      {loading ? (
        <p className={styles.loading}>Loading...</p> // Display loading state
      ) : (
        cryptos.slice(0, displayCount).map(crypto => (
          <div key={crypto.id} className={styles.cryptoItem}>
            <Image 
              src={crypto.icon} 
              alt={crypto.name} 
              className={styles.cryptoIcon} 
              width={50} // Adjust width as needed
              height={50} // Adjust height as needed
            />
            <div className={styles.cryptoDetails}>
              <div className={styles.cryptoName}>{crypto.name}</div>
              <div className={styles.cryptoPrice}>${crypto.price.toFixed(2)}</div>
              <div className={`${styles.cryptoChange} ${crypto.change >= 0 ? styles.positiveChange : styles.negativeChange}`}>
                {crypto.change >= 0 ? `+${crypto.change.toFixed(2)}%` : `${crypto.change.toFixed(2)}%`}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CryptoBar;
