import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { formatDistanceToNow } from 'date-fns';
import Link from 'next/link';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import styles from '../css/NewsSection.module.css';

const NewsSection = ({ latestNews, featuredNews, pumpedCryptos, tradingSignals, dailyAnalysis }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [loading, setLoading] = useState(true);
  const [parsedFeaturedNews, setParsedFeaturedNews] = useState(null);
  const [sortedLatestNews, setSortedLatestNews] = useState([]);
  const [sortedTradingSignals, setSortedTradingSignals] = useState([]);
  const [sortedDailyAnalysis, setSortedDailyAnalysis] = useState([]);
  const [sortedPumpedCryptos, setSortedPumpedCryptos] = useState([]);

  // Parse JSON safely
  const parseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      console.error('Failed to parse JSON', e);
      return [];
    }
  };

  // Parse and sort data
  const parseAndSortData = (data) => {
    return data.map(item => ({
      ...item,
      images: parseJSON(item.images),
    })).sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      // Simulate a delay to show the skeleton loader
      await new Promise(resolve => setTimeout(resolve, 2000));

      setParsedFeaturedNews(featuredNews ? {
        ...featuredNews,
        images: parseJSON(featuredNews.images),
      } : null);
      setSortedLatestNews(parseAndSortData(latestNews));
      setSortedTradingSignals(parseAndSortData(tradingSignals));
      setSortedDailyAnalysis(parseAndSortData(dailyAnalysis));
      setSortedPumpedCryptos(parseAndSortData(pumpedCryptos));

      setLoading(false);
    };

    loadData();
  }, [featuredNews, latestNews, tradingSignals, dailyAnalysis, pumpedCryptos]);

  const renderSkeletonLoader = (key) => (
    <ContentLoader
      key={key}
      speed={2}
      width={400}
      height={300}
      viewBox="0 0 400 300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={styles.newsItem}
    >
      <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
      <rect x="0" y="210" rx="5" ry="5" width="300" height="20" />
      <rect x="0" y="240" rx="5" ry="5" width="250" height="20" />
    </ContentLoader>
  );

  const renderResizedImage = (imageUrl, altText, smallerSize = false) => {
    const aspectRatioStyle = {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      objectFit: 'cover',
    };

    const imageSizeStyle = smallerSize ? {
      width: '70px',
      height: '70px',
    } : {};

    return (
      <Image
        src={imageUrl}
        alt={altText}
        className={styles.cryptoImage}
        style={{ ...aspectRatioStyle, ...imageSizeStyle }}
        loading="lazy"
        width={smallerSize ? 70 : 400}  // Adjust width based on smallerSize
        height={smallerSize ? 70 : 300} // Adjust height based on smallerSize
      />
    );
  };

  const renderResizedImage1 = (imageUrl, altText, smallerSize = false) => {
    const aspectRatioStyle = {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      objectFit: 'cover',
    };
  
    const imageSizeStyle = smallerSize ? {
      width: '70px',
      height: '70px',
    } : {};
  
    return (
      <Image
        src={imageUrl}
        alt={altText}
        className={styles.cryptoImage}
        style={{ ...aspectRatioStyle, ...imageSizeStyle }}
        width={smallerSize ? 70 : 400}  // Adjust width based on smallerSize
        height={smallerSize ? 70 : 300} // Adjust height based on smallerSize
        priority  // Add priority prop here
      />
    );
  };

  return (
    <div className={styles.newsSection}>
      {isMobile ? (
        <>
          <div className={styles.featured}>
            {loading || !parsedFeaturedNews ? (
              renderSkeletonLoader('featured')
            ) : (
              <>
                <Link href={`/news/${parsedFeaturedNews?.slug}`}>
                  <div className={styles.aspectRatioBox}>
                    {renderResizedImage1(parsedFeaturedNews.images[0], parsedFeaturedNews.title)}
                  </div>
                </Link>
                <Link href={`/news/${parsedFeaturedNews?.slug}`} passHref>
                  <h2>{parsedFeaturedNews.title}</h2>
                </Link>
              </>
            )}
          </div>

          <div className={styles.sidebar}>
            <h2>Daily Analysis</h2>
            <ul>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
              ) : (
                sortedDailyAnalysis.map((crypto, index) => (
                  <li key={index} className={styles.pumpedCryptoItem}>
                    {renderResizedImage(crypto.images[0], crypto.title, true)}
                    <div className={styles.cryptoDetails}>
                      <Link href={`/pumped-news/${crypto?.slug}`} className={styles.cryptoTitle} passHref>
                        {crypto.title}
                      </Link>
                      <p className={styles.cryptoDate}>
                        {formatDistanceToNow(new Date(crypto.date), { addSuffix: true })}
                      </p>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>

          <div className={styles.latestNews}>
            <h2>Trading Signals</h2>
            <ul>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
              ) : (
                sortedTradingSignals.map((news, index) => (
                  <li key={index} className={styles.latestNewsItem}>
                    <div className={styles.cryptoDetails}>
                      <Link href={`/news/${news?.slug}`} className={styles.cryptoTitle2} passHref>
                       {news.title}
                      </Link>
                      <p className={styles.cryptoDate}>
                        {formatDistanceToNow(new Date(news.date), { addSuffix: true })}
                      </p>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>

          <div className={styles.sidebar}>
            <h2>Pumped Cryptos</h2>
            <ul>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
              ) : (
                sortedPumpedCryptos.map((crypto, index) => (
                  <li key={index} className={styles.pumpedCryptoItem}>
                    {renderResizedImage(crypto.images[0], crypto.title, true)}
                    <div className={styles.cryptoDetails}>
                      <Link href={`/pumped-news/${crypto?.slug}`} className={styles.cryptoTitle} passHref>
                      {crypto.title}
                      </Link>
                      <p className={styles.cryptoDate}>
                        {formatDistanceToNow(new Date(crypto.date), { addSuffix: true })}
                      </p>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>

          <div className={styles.latestNews}>
            <h2>Trending Today</h2>
            <ul>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
              ) : (
                sortedLatestNews.map((news, index) => (
                  <li key={index} className={styles.latestNewsItem}>
                    <div className={styles.cryptoDetails}>
                      <Link href={`/news/${news?.slug}`} className={styles.cryptoTitle2} passHref>
                       {news.title}
                      </Link>
                      <p className={styles.cryptoDate}>
                        {formatDistanceToNow(new Date(news.date), { addSuffix: true })}
                      </p>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className={styles.sidebar}>
            <h2>Pumped Cryptos</h2>
            <ul>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
              ) : (
                sortedPumpedCryptos.map((crypto, index) => (
                  <li key={index} className={styles.pumpedCryptoItem}>
                    {renderResizedImage(crypto.images[0], crypto.title, true)}
                    <div className={styles.cryptoDetails}>
                      <Link href={`/pumped-news/${crypto?.slug}`} className={styles.cryptoTitle} passHref>
                       {crypto.title}
                      </Link>
                      <p className={styles.cryptoDate}>
                        {formatDistanceToNow(new Date(crypto.date), { addSuffix: true })}
                      </p>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>

          <div className={styles.featured}>
            {loading || !parsedFeaturedNews ? (
              renderSkeletonLoader('featured')
            ) : (
              <>
                <Link href={`/news/${parsedFeaturedNews?.slug}`}>
                  <div className={styles.aspectRatioBox}>
                    {renderResizedImage(parsedFeaturedNews.images[0], parsedFeaturedNews.title)}
                  </div>
                </Link>
                <Link href={`/news/${parsedFeaturedNews?.slug}`} passHref>
                  <h2>{parsedFeaturedNews.title}</h2>
                </Link>
              </>
            )}
          </div>

          <div className={styles.latestNews}>
            <h2>Trending Today</h2>
            <ul>
              {loading ? (
                Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
              ) : (
                sortedLatestNews.map((news, index) => (
                  <li key={index} className={styles.latestNewsItem}>
                    <div className={styles.cryptoDetails}>
                      <Link href={`/news/${news?.slug}`} className={styles.cryptoTitle2} passHref>
                        {news.title}
                      </Link>
                      <p className={styles.cryptoDate}>
                        {formatDistanceToNow(new Date(news.date), { addSuffix: true })}
                      </p>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsSection;
