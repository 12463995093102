import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { formatDistanceToNow } from 'date-fns';
import styles from '../css/NewsSection2.module.css';
import Link from 'next/link';
import Image from 'next/image';

const NewsSection2 = ({ popularNews, featuredNews, pressReleases }) => {
  const [loading, setLoading] = useState(true);

  const parseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      console.error('Failed to parse JSON', e);
      return [];
    }
  };

  const parsedPopularNews = popularNews.map(news => ({
    ...news,
    images: parseJSON(news.images),
  }));

  const parsedFeaturedNews = featuredNews.map(news => ({
    ...news,
    images: parseJSON(news.images),
  }));

  const parsedPressReleases = pressReleases.map(release => ({
    ...release,
    images: parseJSON(release.images),
  }));

  const sortedPopularNews = parsedPopularNews.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  const sortedPressReleases = parsedPressReleases.slice().sort((a, b) => new Date(b.date) - new Date(a.date));

  useEffect(() => {
    setLoading(false);
  }, []);

  const renderSkeletonLoader = (key) => (
    <ContentLoader
      key={key}
      speed={2}
      width={400}
      height={300}
      viewBox="0 0 400 300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={styles.newsItem}
    >
      <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
      <rect x="0" y="210" rx="5" ry="5" width="300" height="20" />
      <rect x="0" y="240" rx="5" ry="5" width="250" height="20" />
    </ContentLoader>
  );

  return (
    <div className={styles.newsSection}>
      <div className={styles.sidebar}>
        <h2>Popular News</h2>
        <ul>
          {loading
            ? Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
            : sortedPopularNews.map((crypto, index) => (
              <li key={index} className={styles.pumpedCryptoItem}>
                <Image
                  src={crypto.images[0]}
                  alt={crypto.title}
                  width={400}
                  height={200}
                  className={styles.cryptoImage}
                  loading="lazy"
                />
                <div className={styles.cryptoDetails}>
                  <Link href={`/news/${crypto.slug}`} target="_blank" rel="noopener noreferrer" className={styles.cryptoTitle}>
                    <h3 className={styles.cryptoh4}>{crypto.title}</h3>
                  </Link>
                  <p className={styles.cryptoDate}>
                    {formatDistanceToNow(new Date(crypto.date), { addSuffix: true })}
                  </p>
                </div>
              </li>
            ))}
        </ul>
      </div>

      <div className={styles.featured}>
        <h2>Featured News</h2>
        {loading
          ? Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
          : parsedFeaturedNews.map((item, index) => (
            <div key={index} className={styles.featuredItem}>
              <Image
                src={item.images[0]}
                alt={item.title}
                width={400}
                height={200}
                className={styles.cryptoImage2}
                loading="lazy"
              />
              <div className={styles.cryptoDetails}>
                <Link href={`/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className={styles.cryptoTitle}>
                  <h3 className={styles.cryptoh3}>{item.title}</h3>
                </Link>
                <p className={styles.itemdate}>{formatDistanceToNow(new Date(item.date), { addSuffix: true })}</p>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      </div>

      <div className={styles.pressReleases}>
        <h2>Press Releases</h2>
        {loading
          ? Array.from({ length: 3 }).map((_, index) => renderSkeletonLoader(index))
          : sortedPressReleases.map((item, index) => (
            <div key={index} className={styles.pressRelease}>
              <Image
                src={item.images[0]}
                alt={item.title}
                width={400}
                height={200}
                className={styles.cryptoImage}
                loading="lazy"
              />
              <div className={styles.cryptoDetails}>
                <Link href={`/press-release/${item.slug}`} target="_blank" rel="noopener noreferrer" className={styles.cryptoTitle}>
                  <h3 className={styles.cryptoh4}>{item.title}</h3>
                </Link>
                <p className={styles.cryptoDate}>
                  {formatDistanceToNow(new Date(item.date), { addSuffix: true })}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default NewsSection2;
