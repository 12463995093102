import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import Navbar from '../components/NavBar';
import NewsSection from '../components/NewsSection';
import NewsletterSignup from '../components/NewsletterSignup';
import NewsSection2 from '../components/NewsSection-2';
import Footer from '../components/Footer';
import CryptoBar from '../components/CryptoBar';
import HeadingBar from '../components/HeadingBar';
import EarnProgramBox from '../components/EarnProgram';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import { parseISO, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz'; 
import timeZoneAbbreviations from '../components/timeZoneAbbreviations';
import { useInView } from 'react-intersection-observer';

const SkeletonLoader = dynamic(() => import('../components/SkeletonLoader'), {
  loading: () => (
     <div className="loading-container">
      <div className="loading-box"></div>
      <div className="loading-box"></div>
      <div className="loading-box"></div>
      <div className="loading-box"></div>
    </div>
  ),
});

const NewsSectionLoader = dynamic(() => import('../components/NewsSectionLoader'), { ssr: false });
const AboveNews = dynamic(() => import('../components/AboveNewsBar'), { ssr: true, loading: () => <SkeletonLoader /> });
const AboveNews3 = dynamic(() => import('../components/AboveNewsBar3'), { ssr: true, loading: () => <SkeletonLoader /> });
const AboverNewsBar2 = dynamic(() => import('../components/AboveNewsBar2'), { ssr: true, loading: () => <SkeletonLoader /> });
const NewsSection3 = dynamic(() => import('../components/NewsSection3'), { ssr: false, loading: () => <SkeletonLoader /> });

const formatDateTime = (dateString) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = parseISO(dateString);
  const zonedDate = toZonedTime(date, userTimeZone);
  const formattedDate = format(zonedDate, 'dd MMMM yyyy, h:mm a', { timeZone: userTimeZone });
  const abbreviation = timeZoneAbbreviations[userTimeZone] || 'UTC';
  return `${formattedDate} (${abbreviation})`;
};

const TitlePage = ({
  featuredNews,
  pumpedNews,
  latestNews,
  popularNews,
  topFeaturedNews,
  pressReleases,
  allNews,
  tradingSignals,
  dailyAnalysis,
  airDrop,
  headlines,
  fearGreedIndex,
  value_classification,
  upcomingEvents,
  recentEvents,
}) => {
  const [loading, setLoading] = useState(true);
  const [isCSR, setIsCSR] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { ref: newsSection3Ref, inView: newsSection3InView } = useInView({ triggerOnce: true });
  const { ref: footerRef, inView: footerInView } = useInView({ triggerOnce: true });

  useEffect(() => {
    setIsCSR(true); // Indicates the client-side rendering has started
    setLoading(false); // Simulate data fetching completion
  }, []);

  return (
    <div className="title-page">
      <Head>
      <title>DailyCoinMarket - Top Crypto News, Analysis & Insights | Daily Coin Market</title>
        <meta name="description" content="Discover DailyCoinMarket for the latest and most comprehensive crypto news, analysis, and insights. Stay ahead with expert reviews, market trends, and trading signals on Daily Coin Market." />
        <meta name="keywords" content="dailycoinmarket, Daily Coin Market, crypto news, cryptocurrency analysis, latest crypto news, crypto market insights, blockchain updates, digital currency, crypto trading signals, Bitcoin analysis, Ethereum trends, crypto price prediction, market trends, DeFi news, NFT news, crypto investment strategies, altcoin updates, crypto regulations, market analysis, crypto portfolio management, cryptocurrency forecasts, blockchain technology, crypto trading tips, market news, fintech, crypto research, investment opportunities, crypto expert opinions, cryptocurrency reviews, token analysis, crypto charts" />
        <link rel="canonical" href="https://dailycoinmarket.com/" />
        <meta property="og:title" content="DailyCoinMarket - Latest Crypto News and Analysis" />
        <meta property="og:description" content="Stay ahead in the crypto market with DailyCoinMarket. Your daily dose of crypto insights, latest news, and analysis." />
        <meta property="og:image" content="https://dailycoinmarket.com/upload/default-image.jpg" />
        <meta property="og:url" content="https://dailycoinmarket.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="DailyCoinMarket - Latest Crypto News and Analysis" />
        <meta name="twitter:description" content="Stay ahead in the crypto market with DailyCoinMarket. Your daily dose of crypto insights, latest news, and analysis." />
        <meta name="twitter:image" content="https://dailycoinmarket.com/upload/default-image.jpg" />
      </Head>
      <Navbar />
      <HeadingBar headlines={headlines} />
      <CryptoBar />
      <EarnProgramBox />

        {isCSR ? (
        <>
          {!isMobile && (
            loading ? <SkeletonLoader /> : <AboveNews fearGreedIndex={fearGreedIndex} value_classification={value_classification} upcomingEvents={upcomingEvents} recentEvents={recentEvents} tradingSignals={tradingSignals} />
          )}
          {isMobile && (
            loading ? <SkeletonLoader /> : <AboveNews3 fearGreedIndex={fearGreedIndex} value_classification={value_classification} />
          )}
          {!isMobile && (
            loading ? <SkeletonLoader /> : <AboverNewsBar2 airDrop={airDrop} dailyAnalysis={dailyAnalysis} />
          )}
          {loading ? <NewsSectionLoader /> : featuredNews && (
            <NewsSection latestNews={latestNews} featuredNews={featuredNews} pumpedCryptos={pumpedNews} tradingSignals={tradingSignals} dailyAnalysis={dailyAnalysis} />
          )}
          {loading ? <NewsSectionLoader /> : topFeaturedNews.length > 0 && (
            <NewsSection2 popularNews={popularNews} featuredNews={topFeaturedNews.slice(0, 4)} pressReleases={pressReleases} />
          )}
          <div ref={newsSection3Ref}>
            {newsSection3InView && (
              loading ? <NewsSectionLoader /> : <NewsSection3 latestNews={allNews} />
            )}
          </div>
        </>
      ) : (
        // Server-side loading indicator
        <SkeletonLoader />
      )}

      <div ref={footerRef}>
        {footerInView && <Footer />}
      </div>
    </div>
  );
};

export async function getStaticProps() {
  const fetchData = async () => {
    try {
      const [
        featuredData,
        allNewsData,
        topPressData,
        topFeaturedData,
        topPopularData,
        topLatestData,
        topPumpedData,
        tradingSignalsData,
        dailyAnalysisData,
        airDropData,
        headlinesData, 
        fearGreedData,
        upcomingEventsData,
        recentEventsData
      ] = await Promise.all([
        fetch('https://dailycoinmarket.com/get-featured-news').then(response => response.json()),
        fetch('https://dailycoinmarket.com/get-all-news').then(response => response.json()),
        fetch('https://dailycoinmarket.com/get-top-press-release-with-details').then(response => response.json()),
        fetch('https://dailycoinmarket.com/get-top-featured-cryptos-with-latest-news').then(response => response.json()),
        fetch('https://dailycoinmarket.com/get-top-popular-cryptos-with-latest-news').then(response => response.json()),
        fetch('https://dailycoinmarket.com/get-top-latest-cryptos-with-news').then(response => response.json()),
        fetch('https://dailycoinmarket.com/get-top-pumped-cryptos-with-news').then(response => response.json()),
        fetch('https://dailycoinmarket.com/api/recent-trading-signals').then(response => response.json()),
        fetch('https://dailycoinmarket.com/api/recent-daily-analysis').then(response => response.json()),
        fetch('https://dailycoinmarket.com/api/air-drop').then(response => response.json()),
        fetch('https://dailycoinmarket.com/api/headlines').then(response => response.json()), 
        fetch('https://api.alternative.me/fng/').then(response => response.json()).then(data => ({
          fearGreedIndex: parseInt(data.data[0].value, 10),
          value_classification: data.data[0].value_classification || 'unknown'
        })),
        fetch('https://dailycoinmarket.com/top-get-upcoming-event').then(response => response.json()),
        fetch('https://dailycoinmarket.com/top-get-recent-event').then(response => response.json())
      ]);
      
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Format dates here
      const formatEvents = (events) => events.map(event => ({
        ...event,
        formattedDate: formatDateTime(event.event_date_time, userTimeZone)
      }));

      return {
        props: {
          featuredNews: featuredData,
          pumpedNews: topPumpedData.pumpedNews,
          latestNews: topLatestData.latestNews,
          popularNews: topPopularData.latestNews,
          topFeaturedNews: topFeaturedData.latestNews,
          pressReleases: topPressData.pressReleaseDetails,
          allNews: allNewsData,
          tradingSignals: tradingSignalsData.map(signal => ({
            ...signal,
            formattedDate: formatDateTime(signal.date, userTimeZone)
          })),
          dailyAnalysis: dailyAnalysisData,
          airDrop: airDropData,
          headlines: headlinesData,
          fearGreedIndex: fearGreedData.fearGreedIndex,
          value_classification: fearGreedData.value_classification,
          upcomingEvents: formatEvents(upcomingEventsData.data),
          recentEvents: formatEvents(recentEventsData.data)
        }
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        props: {
          featuredNews: null,
          pumpedNews: [],
          latestNews: [],
          popularNews: [],
          topFeaturedNews: [],
          pressReleases: [],
          allNews: [],
          tradingSignals: [],
          dailyAnalysis: [],
          airDrop: [],
          headlines: [],
          fearGreedIndex: 0,
          value_classification: 'unknown',
          upcomingEvents: [],
          recentEvents: []
        },
        revalidate: 10, 
      };
    }
  };

  return fetchData();
}

export default TitlePage;